<template>
 <div>
  مرحبا بك ...
 </div>
</template>

<script>

export default {

};
</script>
